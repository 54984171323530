<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="faqs"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <vx-tooltip text="Add New Topic" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              @click="addNewTopic()"
              icon="icon-plus"
              color="success"
            ></vs-button>
          </vx-tooltip>
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="question">Question</vs-th>
          <vs-th sort-key="answer" width="55%">Answer</vs-th>
          <vs-th sort-key="action" size="35%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].question">{{ data[indextr].question }}</vs-td>

            <vs-td :data="data[indextr].answer">{{
              data[indextr].answer
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <vx-tooltip text="More Options" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="editFaqsDetail(data[indextr]._id)"
                    icon="icon-eye"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete FAQs" position="top" delay=".3s">
                  <vs-button
                    class="ml-2"
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                    icon="icon-trash"
                    color="primary"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      faqs: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedTopic: {},
      selectedIndex: "",
    };
  },
  methods: {
    ...mapActions("admin", ["fetchFAQs", "deleteFAQsTopic"]),
    ...mapActions(["initToFirstPage"]),
    moment(date) {
      return moment(date);
    },
    getFaqsList() {
      let self = this;
      this.fetchFAQs(self.dataTableParams).then((res) => {
        self.faqs = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getFaqsList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getFaqsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getFaqsList();
    },
    addNewTopic() {
      this.$router.push({ name: "FAQsNew" });
    },
    editFaqsDetail(id) {
      this.$router.push({ name: "FAQsEdit", params: { id: id } });
    },

    deleteDetailHandler(t, i) {
      this.selectedTopic = t;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `Are you sure?`,
        accept: this.confirmDeleteRecord,
        acceptText: "Delete",
      });
    },
    confirmDeleteRecord() {
      this.deleteFAQsTopic(this.selectedTopic._id).then((res) => {
        this.$vs.notify({
          title: "FAQ Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.faqs.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getFaqsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getFaqsList();
      }
    },
  },
  created() {
    this.initToFirstPage({ pageType: "all"});
    this.getFaqsList();
  },
};
</script>
